import { Trans } from 'gatsby-plugin-react-i18next';
import React from 'react';

import Article from './Article';
import SectionTitle from './SectionTitle';

const Overview = () => {
  return (
    <div className="py-56 md:py-80 ">
      <div className="container-slim">
        <SectionTitle number={1} title={<Trans>Overview</Trans>} />
        <div className="mt-40 md:mt-64 space-y-32 md:space-y-64">
          <Article
            title={<Trans>Code Web2, Output Web3</Trans>}
            text={
              <Trans>
                Paima Engine is the only blockchain gaming engine that enables developers to reuse their Web2
                programming skill sets to build fully trustless Web3 games (or apps). Devs can apply their years of
                knowledge in JavaScript, relational databases, and all parts of the classic Web2 stack with Paima
                Engine, while fully taking advantage of the bleeding edge in Layer-2 blockchain technology.
              </Trans>
            }
            imageUrl="paima-engine-overview-1.webp"
          />
          <Article
            imageSide="left"
            title={<Trans>Deploy Once, Target Every Chain</Trans>}
            text={
              <Trans>
                Through a novel piece of technology called Paima Whirlpool, users from any blockchain ecosystem can use
                their favorite wallets to play your Paima Engine-based Web3 games! No longer are you locked into a
                single ecosystem; simply deploy on the chain you prefer and still target every single user in the entire
                crypto space.
              </Trans>
            }
            imageUrl="paima-engine-overview-2.webp"
          />
        </div>
      </div>
    </div>
  );
};

export default Overview;
