import classNames from 'classnames';
import React, { ReactElement } from 'react';

interface ArticleProps {
  title: string | ReactElement;
  text: string | ReactElement;
  imageUrl: string;
  imageSide?: 'left' | 'right';
  additionalComponent?: React.ReactElement | React.ReactElement[] | React.ReactNode | React.ReactNode[];
}

const Article = ({ title, text, imageUrl, imageSide = 'right', additionalComponent }: ArticleProps) => {
  return (
    <article
      className={classNames(
        'flex flex-col-reverse md:items-center',
        imageSide === 'left' ? 'md:flex-row-reverse' : 'md:flex-row',
        'gap-16 md:gap-32 lg:gap-48'
      )}
    >
      <div>
        <h3 className="text-24 md:text-32 font-bold">{title}</h3>
        <p className="mt-16 text-16">{text}</p>
        {additionalComponent && <div className="mt-24">{additionalComponent}</div>}
      </div>
      {!!imageUrl && (
        <div className="md:flex-1 md:shrink-0 md:basis-[346px] lg:basis-[516px]">
          <img src={`/images/${imageUrl}`} alt="" className="rounded-[24px] md:rounded-[40px]" />
        </div>
      )}
    </article>
  );
};

export default Article;
