import classNames from 'classnames';
import { Trans } from 'gatsby-plugin-react-i18next';
import React, { ReactElement, useState } from 'react';

import SectionTitle from './SectionTitle';

interface Feature {
  tabId: string;
  tabLabel: string | ReactElement;
  title: string | ReactElement;
  text: string | React.ReactNode | React.ReactFragment | React.ReactElement;
  imageUrl: string;
  // background classnames can't be template literals for Tailwind to not purge these classes as unused
  tabImageBgClassname: string;
}

const features: Feature[] = [
  {
    tabId: 'templates',
    tabLabel: <Trans>Templates: Game MVP In Days</Trans>,
    title: <Trans>Templates: Game MVP In Days</Trans>,
    tabImageBgClassname:
      "bg-[url('/images/paima-engine-features-tab-templates.webp')] md:bg-[url('/images/paima-engine-features-tab-templates-tablet.webp')]",
    text: (
      <>
        <Trans>
          Classical blockchain game development is extremely time-consuming, often taking months, if not years, just to
          ship a barebones MVP. In the world of Web3 game development, this is unacceptable, as it limits building games
          to projects that over-hype themselves just to raise enough funding to get started.
        </Trans>
        <br />
        <br />
        <Trans>
          We at Paima Studios truly believe that Web3 gaming is the future, and as such, we consider it a key goal to
          enable competent teams to ship MVPs in days, not months or years. Paima Game Templates specifically address
          this issue by allowing experienced developers to create all of the foundational (thus
          time-consuming/potentially complex) logic and structure for a category of games, which anyone can then reuse
          to build off of quickly.
        </Trans>
        <br />
        <br />
        <Trans>
          Why redo the work from scratch when others in the Paima ecosystem have already put in the time and effort
          up-front? Paima Game Templates allow bootstrapping a new project in minutes, yet offer the full freedom to
          expand or change anything to meet the exact requirements of your project.
        </Trans>
      </>
    ),
    imageUrl: 'paima-engine-features-templates.webp'
  },
  {
    tabId: 'stateful-nfts',
    tabLabel: <Trans>NFTs 2.0: Stateful NFTs</Trans>,
    title: <Trans>Stateful NFTs</Trans>,
    tabImageBgClassname:
      "bg-[url('/images/paima-engine-features-tab-stateful-nfts.webp')] md:bg-[url('/images/paima-engine-features-tab-stateful-nfts-tablet.webp')]",
    text: (
      <>
        <Trans>
          NFTs in gaming today have a bad rap, and for good reason; they simply aren't powerful enough alone to build
          quality games that players want.
        </Trans>
        <br />
        <br />
        <Trans>
          Players want NFTs that feel just as alive as their characters in an MMORPG; they should level up, gain
          experience, obtain equipment, and develop over time with the player. Instead, current NFTs are as exciting as
          a pet rock that never changes.
        </Trans>
        <br />
        <br />
        <Trans>
          Paima Engine is pioneering NFTs 2.0: Stateful NFTs, which unlock a whole new class of Web3 games that were
          never possible before. Any piece of in-game state can be attached to a user's NFT, unlocking the potential of
          MMOs in the Web3 world, yet with all of the security of the underlying blockchain intact.
        </Trans>
        <br />
        <br />
        <Trans>
          This has never been possible before, and thus we believe Stateful NFTs are one of the most promising
          innovations on both a technological and monetization level for the next era of Web3.
        </Trans>
      </>
    ),
    imageUrl: 'paima-engine-nft-20.png'
  },
  {
    tabId: 'paima-whirlpool',
    tabLabel: <Trans>Paima Whirlpool: Cross-Chain Play</Trans>,
    title: <Trans>Paima Whirlpool: Cross-Chain Play</Trans>,
    tabImageBgClassname:
      "bg-[url('/images/paima-engine-features-tab-paima-batcher.webp')] md:bg-[url('/images/paima-engine-features-tab-paima-batcher-tablet.webp')]",
    text: (
      <>
        <Trans>
          All existing Web3 games and engines limit your user base by forcing you to select a single blockchain. After
          examining the space, we made it a priority to unlock Cross-Chain Play as a core feature available for every
          game.
        </Trans>
        <br />
        <br />
        <Trans>
          With Paima Whirlpool, every user in the blockchain ecosystem with a crypto wallet becomes a potential player.
          No need to be stuck writing complicated smart contracts that lock you into a single chain. Gamers are excited
          about quality games, no matter if their favorite chain is Ethereum, Solana, Algorand, Cardano, or any other.
        </Trans>
        <br />
        <br />
        <Trans>Build once, target every ecosystem, and leave no crypto gamer behind.</Trans>
      </>
    ),
    imageUrl: 'paima-engine-features-paima-batcher.webp'
  },

  {
    tabId: 'layer-2-tech',
    tabLabel: <Trans>Decentralized Layer-2 Technology</Trans>,
    title: <Trans>Decentralized Layer-2 Technology</Trans>,
    tabImageBgClassname:
      "bg-[url('/images/paima-engine-features-tab-special-feature.webp')] md:bg-[url('/images/paima-engine-features-tab-special-feature-tablet.webp')]",
    text: (
      <>
        <Trans>
          Paima Engine features the latest in bleeding-edge blockchain technology by implementing games as full-fledged
          Layer-2s. In other words, when you create a game with Paima Engine, you use your Web2 skillset to jump right
          into the world of rollups/L2 technology without needing to learn any of the underlying mechanics.
        </Trans>
        <br />
        <br />
        <Trans>
          The fact that Paima Engine games are a new class of L2s offers numerous benefits, including substantially
          cheaper transaction fees (or hiding all transaction fees from users completely), the ability for cross-chain
          play, brand new features such as Stateful NFTs and passive on-chain time, the ability to write games with
          classical DBs/the Web2 stack, and much more.
        </Trans>
        <br />
        <br />
        <Trans>
          By doing all of the foundational research ourselves at Paima Studios, we have been able to execute and
          implement all of the challenging essentials for a project as ambitious as Paima Engine, which offers game
          developers the benefits of Web3 while hiding the vast majority of complexity. Paima Engine-based L2s inherit
          the underlying security of the blockchain they are deployed on, and unlike other rollup L2 solutions, have
          zero bridging/custodial risk. In other words, we do the Web3 heavy lifting so you can focus on building the
          games players love.
        </Trans>
      </>
    ),
    imageUrl: 'paima-engine-features-special-feature.webp'
  }
];

const FeaturePanel = ({ title, text, imageUrl }: Feature) => {
  return (
    <div className="flex flex-col-reverse md:flex-row lg:items-center gap-16 lg:gap-48">
      <div>
        <h3 className="text-24 md:text-32 text-white font-bold">{title}</h3>
        <p className="text-16 text-engine-gray mt-16">{text}</p>
      </div>
      <div className="md:flex-1 md:shrink-0 md:basis-[346px] lg:basis-[516px] rounded-[40px]">
        <img src={`/images/${imageUrl}`} alt="" className="rounded-[40px]" />
      </div>
    </div>
  );
};

const FeatureTab = ({
  tabId,
  tabLabel,
  tabImageBgClassname,
  onTabClick,
  activeTabId
}: Feature & { onTabClick: (tabId: string) => void; activeTabId: string }) => {
  const isActive = activeTabId === tabId;
  return (
    <li
      onClick={() => onTabClick(tabId)}
      className={classNames(
        'relative',
        'flex items-center justify-center',
        'rounded-[24px]',
        'py-8 px-16',
        'hover:cursor-pointer',
        'hover:underline',
        'h-[88px]',
        'text-center font-bold text-20 text-white leading-6',
        tabImageBgClassname,
        'bg-no-repeat bg-center bg-cover',
        !isActive && 'grayscale'
      )}
    >
      <div
        className="w-full h-full absolute top-0 left-0 rounded-[24px]"
        style={{ background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)' }}
      />
      <span className="relative z-10">{tabLabel}</span>
    </li>
  );
};

const KeyFeatures = () => {
  const [currentTabID, setCurentTabID] = useState(features[0].tabId);

  const currentFeature = features.find((feature) => feature.tabId === currentTabID);

  return (
    <div className="py-56 md:py-80 bg-engine-black text-white border-b border-b-engine-border ">
      <div className="container-slim">
        <SectionTitle number={2} title={<Trans>Key Features</Trans>} darkText={false} />
        <div className="mt-40 md:mt-56 lg:mt-88">
          <ul className="grid grid-cols-2 md:grid-cols-4 gap-16 md:gap-24">
            {features.map((feature) => (
              <FeatureTab
                key={feature.tabId}
                {...feature}
                onTabClick={(tabId) => setCurentTabID(tabId)}
                activeTabId={currentTabID}
              />
            ))}
          </ul>
        </div>
        <div className="mt-40 md:mt-56 lg:mt-88">{currentFeature && <FeaturePanel {...currentFeature} />}</div>
      </div>
      <div className="hidden">
        {features.map((feature, index) => (
          <img
            src={`/images/${feature.imageUrl}`}
            alt=""
            className="hidden"
            loading={index === 0 ? 'eager' : 'lazy'}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default KeyFeatures;
