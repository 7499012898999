import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import VolcaneersLinkButton from '../volcaneers/VolcaneersLinkButton';
import SectionTitle from './SectionTitle';

import YoutubeIcon from '../../../../static/images/icon-youtube-full.inline.svg';
import { socialsConfig } from '../../../config';
import { getLocalizedLink } from '../../../utils/locale';

const ContactUs = () => {
  const { i18n } = useTranslation();

  return (
    <div className="py-56 md:py-80 ">
      <div className="container-slim">
        <SectionTitle number={7} title={<Trans>Contact Us</Trans>} />
        <div className="mt-40 md:mt-64 space-y-24">
          <div className="bg-white rounded-[32px] md:flex md:items-center">
            <div className="flex-1 md:basis-[50%]">
              <img src="/images/paima-engine-contact-us.webp" alt="" className="rounded-[32px]" />
            </div>
            <div className="flex-1 md:basis-[50%] p-24 lg:p-40">
              <h4 className="text-14 uppercase mb-8">
                <Trans>Tutorials</Trans>
              </h4>
              <h3 className="font-bold text-24 lg:text-32 lg:max-w-[400px]">
                <Trans>Watch Our YouTube Videos To Find Out More</Trans>
              </h3>
              <div className="mt-24">
                <VolcaneersLinkButton
                  sizeVariant="small"
                  uppercaseText={false}
                  href={socialsConfig.youtubeUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="fill-white flex items-center gap-8">
                    <span className="w-[24px] h-[24px]">
                      <YoutubeIcon />
                    </span>
                    <Trans>Watch on YouTube</Trans>
                  </span>
                </VolcaneersLinkButton>
              </div>
            </div>
          </div>
          <div className="rounded-[32px] flex flex-col md:flex-row items-center md:items-stretch gap-24">
            <div className="flex-1 md:basis-[50%]">
              <div className="bg-white rounded-[32px] p-24 lg:p-40 md:min-h-full">
                <h4 className="text-24 font-bold">
                  <Trans>Join the Community</Trans>
                </h4>
                <p className="mt-8">
                  <Trans>
                    Join the Paima Ecosystem and take part in building the future of Web3 gaming with us! Our discord is
                    the best place to ask questions about Paima Engine and to take part in the community.
                  </Trans>
                </p>
                <div className="mt-24">
                  <VolcaneersLinkButton
                    outlineVariant
                    sizeVariant="small"
                    uppercaseText={false}
                    href={socialsConfig.discordUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Trans>Join our Discord</Trans>
                  </VolcaneersLinkButton>
                </div>
              </div>
            </div>
            <div className="flex-1 md:basis-[50%] md:min-h-full">
              <div className="bg-white rounded-[32px] p-24 lg:p-40">
                <h4 className="text-24 font-bold">
                  <Trans>Build Games With Paima Engine</Trans>
                </h4>
                <p className="mt-8">
                  <Trans>
                    Interested in becoming one of the first companies to build Web3 games using Paima Engine? We're
                    always open to providing early access to teams who are extremely dedicated, have a solid track
                    record, and are ready to build the future of Web3.
                  </Trans>
                </p>
                <div className="mt-24">
                  <VolcaneersLinkButton
                    outlineVariant
                    sizeVariant="small"
                    uppercaseText={false}
                    href={getLocalizedLink('/contact', i18n.language)}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Trans>Contact Us</Trans>
                  </VolcaneersLinkButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
