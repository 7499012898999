import classNames from 'classnames';
import React, { ReactElement } from 'react';

interface SectionTitleProps {
  title: string | ReactElement;
  number: number;
  darkText?: boolean;
}

const SectionTitle = ({ title, number, darkText = true }: SectionTitleProps) => {
  const prefixedNumber = number < 10 && number !== 0 && number.toString()[0] !== '0' ? `0${number}` : number.toString();
  return (
    <h2
      className={classNames(
        'relative',
        'pl-16',
        'text-32',
        'md:text-48',
        'font-bold',
        'font-heading',
        darkText ? 'text-black' : 'text-white',
        [
          'before:absolute',
          'before:left-0',
          'before:top-0',
          'before:bottom-0',
          'before:w-[4px]',
          'before:h-full',
          'before:bg-engine-primary',
          'before:rounded-[24px]'
        ]
      )}
    >
      {prefixedNumber}. {title}
    </h2>
  );
};

export default SectionTitle;
