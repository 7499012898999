import React, { MutableRefObject, PropsWithChildren } from 'react';

interface RefWrapperProps extends PropsWithChildren {
  refProp: MutableRefObject<any>;
  id?: string;
}

const RefWrapper = ({ refProp, children, id }: RefWrapperProps) => {
  return (
    <section ref={refProp} id={id} className="scroll-mt-[120px]">
      {children}
    </section>
  );
};

export default RefWrapper;
