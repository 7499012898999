import { Link } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';

import VolcaneersLinkButton from '../volcaneers/VolcaneersLinkButton';

import PlayIcon from '../../../../static/images/icon-play.inline.svg';
import { getLocalizedLink } from '../../../utils/locale';
import TowerDefenseButton from '../tower-defense/TowerDefenseButton';

const partners = [
  {
    name: 'Microsoft',
    logo: 'logo-microsoft.png'
  },
  {
    name: 'Astar',
    logo: 'logo-astar.png'
  },
  {
    name: 'Milkomeda',
    logo: 'logo-milkomeda.png'
  },
  {
    name: 'Algorand',
    logo: 'logo-algorand.png'
  }
];

const Hero = () => {
  const { i18n } = useTranslation();

  return (
    <div className="bg-engine-green text-white pt-32 md:pt-48 lg:pt-80 pb-32 md:pb-56 relative overflow-hidden">
      <img
        src="/images/paima-engine-hero-bg.webp"
        alt=""
        className="absolute lg:right-100 lg:bottom-0 md:bottom-[6vw] right-0 2xl:right-[calc((100vw-1114px)/2-13vw)] lg:max-w-[600px] lg:max-h-[600px] xl:max-w-[800px] xl:max-h-[800px] md:max-w-[384px] md:max-h-[384px] z-0 hidden md:block"
      />
      <div className="container-slim z-10 relative">
        <div className="md:max-w-[640px]">
          <h1 className="text-32 sm:text-40 md:text-64 font-bold font-heading">
            <Trans>The Future Of Web3 Gaming, Packed In A Single Engine</Trans>
          </h1>
          <p className="text-18 text-engine-gray mt-16 max-w-[400px]">
            <Trans>
              Build bleeding-edge Web3 games and apps using your team's existing Web2 skill set, without the
              complexities of blockchain.
            </Trans>
          </p>
          <div className="mt-24 md:mt-32 flex flex-col gap-16 md:flex-row items-center justify-center md:justify-start">
            <VolcaneersLinkButton
              sizeVariant="small"
              uppercaseText={false}
              href={"https://github.com/PaimaStudios/paima-engine/"}
              target="_blank"
            >
              <Trans>See the code</Trans>
            </VolcaneersLinkButton>
            <VolcaneersLinkButton
              outlineVariant
              invertColor
              sizeVariant="small"
              uppercaseText={false}
              href={getLocalizedLink('/contact', i18n.language)}
              target="_blank"
              rel="noreferrer"
            >
              <Trans>Contact Us</Trans>
            </VolcaneersLinkButton>
            <Link
              to="https://www.youtube.com/watch?v=2-FTP-qbml8"
              target="_blank"
              className="text-engine-gray text-14 flex items-center gap-10 hover:text-white group fill-engine-gray stroke-engine-gray hover:fill-white hover:stroke-white"
            >
              <span className="w-[40px] h-[40px] block">
                <PlayIcon />
              </span>
              <span>
                <Trans>Play Paima Engine Intro Video</Trans>
              </span>
            </Link>
          </div>
        </div>

        {/* <div className="glass-bg py-24 px-16 rounded-[32px] mt-40 md:mt-72">
          <h2 className="font-bold text-20 leading-6 text-center">
            <Trans>Paima Ecosystem Partners</Trans>
          </h2>
          <ul className="mt-16 md:mt-24 gap-16 grid grid-cols-2 xs:grid-cols-4">
            {partners.map((partner, index) => (
              <li key={index}>
                <img
                  src={`/images/${partner.logo}`}
                  alt={`Logo for ${partner.name}`}
                  className="mx-auto w-full max-w-[120px] max-h-[27px] md:max-w-[180px] md:max-h-[40px]"
                />
              </li>
            ))}
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default Hero;
