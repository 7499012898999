import React, { useRef } from 'react';

import CookieConsentBanner from '../../CookieConsentBanner';
import Footer from '../Footer';
import Header from '../header/Header';
import Architecture from './Architecture';
import ContactUs from './ContactUs';
import EcosystemPartners from './EcosystemPartners';
import Hero from './Hero';
import KeyFeatures from './KeyFeatures';
import LaunchedGames from './LaunchedGames';
import Overview from './Overview';
import RefWrapper from './RefWrapper';
import StartBuilding from './StartBuilding';

const PaimaEngineLayout = () => {
  const overviewRef = useRef(null);
  const keyFeaturesRef = useRef(null);
  const architectureRef = useRef(null);
  const launchedGamesRef = useRef(null);
  const startBuildingNowRef = useRef(null);
  const ecosystemRef = useRef(null);
  const contactUsRef = useRef(null);

  return (
    <div className="relative bg-engine-white">
      <Header
        showSubHeader="paima-engine"
        scrollSpySectionRefs={[
          overviewRef,
          keyFeaturesRef,
          architectureRef,
          launchedGamesRef,
          startBuildingNowRef,
          ecosystemRef,
          contactUsRef
        ]}
      />
      <CookieConsentBanner />
      <Hero />
      <RefWrapper refProp={overviewRef} id="overview">
        <Overview />
      </RefWrapper>
      <RefWrapper refProp={keyFeaturesRef} id="key-features">
        <KeyFeatures />
      </RefWrapper>
      <RefWrapper refProp={architectureRef} id="architecture">
        <Architecture />
      </RefWrapper>
      <RefWrapper refProp={launchedGamesRef} id="launched-games">
        <LaunchedGames />
      </RefWrapper>
      <RefWrapper refProp={startBuildingNowRef} id="start-building-now">
        <StartBuilding />
      </RefWrapper>
      {/* <RefWrapper refProp={ecosystemRef} id="paima-engine-ecosystem">
        <EcosystemPartners />
      </RefWrapper> */}
      <RefWrapper refProp={contactUsRef} id="contact-us">
        <ContactUs />
      </RefWrapper>
      <Footer />
    </div>
  );
};

export default PaimaEngineLayout;
