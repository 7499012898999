import classNames from 'classnames';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { ReactElement } from 'react';
import { getLocalizedLink } from '../../../utils/locale';

import VolcaneersLinkButton from '../volcaneers/VolcaneersLinkButton';
import SectionTitle from './SectionTitle';

const ImageTile = ({
  imageUrl,
  text,
  smallRadius = false
}: {
  imageUrl: string;
  text: string | ReactElement;
  smallRadius?: boolean;
}) => {
  return (
    <div className="relative">
      <img
        src={imageUrl}
        alt=""
        className={classNames(smallRadius ? 'rounded-[24px]' : 'rounded-[32px]', 'w-full h-auto')}
      />
      <div
        className={classNames(
          smallRadius ? 'rounded-[24px]' : 'rounded-[32px]',
          'w-full h-auto',
          'absolute left-0 right-0 top-0 bottom-0 w-full h-full',
          'image-overlay-gradient',
          'z-10'
        )}
      />
      <p
        className={classNames(
          'absolute bottom-24 left-0 right-0 w-full',
          smallRadius ? 'px-16 md:px-24' : 'px-16 md:px-24 lg:px-32',
          'text-center text-20 leading-6 font-bold text-white',
          'z-20'
        )}
      >
        {text}
      </p>
    </div>
  );
};

const StartBuilding = () => {
  const { i18n } = useTranslation();

  return (
    <div className="py-56 md:py-80 ">
      <div className="container-slim">
        <SectionTitle number={5} title={<Trans>Start Building</Trans>} />
        <div className="mt-40 lg:mt-64 flex flex-col md:flex-row md:flex-wrap lg:flex-nowrap gap-32 md:gap-40">
          <div className="lg:basis-[30%] flex-1 flex flex-col">
            <ImageTile
              imageUrl="/images/paima-engine-start-basics.webp"
              text={<Trans>Writing A Game With Paima Engine</Trans>}
            />
            <div className="mt-24 lg:h-full flex flex-col justify-between">
              <div>
                <h3 className="font-bold text-24">
                  <Trans>Learn The Basics Of Paima Engine</Trans>
                </h3>
                <p className="mt-16">
                  <Trans>
                    Jump-start your knowledge by learning about the basics of what it takes to build a game with Paima
                    Engine. In the video below, the creator of the Engine will take you through an example project and
                    lay out all of the essential parts in an easy-to-follow fashion. All you need is some previous
                    experience with JS/TS, along with an open mind, and you'll start your journey into understanding the
                    future of Web3 gaming.
                  </Trans>
                </p>
              </div>
              <div className="mt-24">
                <VolcaneersLinkButton
                  outlineVariant
                  sizeVariant="small"
                  uppercaseText={false}
                  className="block md:inline-block lg:block"
                  href="https://www.youtube.com/watch?v=2-FTP-qbml8"
                  target="_blank"
                >
                  <Trans>Watch The Engine Basics Video</Trans>
                </VolcaneersLinkButton>
              </div>
            </div>
          </div>
          <div className="lg:basis-[30%] flex-1 flex flex-col">
            <div className="grid grid-cols-2 grid-rows-2 gap-16">
              <ImageTile imageUrl="/images/paima-engine-start-pvp.webp" text="" smallRadius />
              <ImageTile imageUrl="/images/paima-engine-start-card-game.webp" text="" smallRadius />
              <ImageTile imageUrl="/images/paima-engine-start-mmorpg.webp" text="" smallRadius />
              <ImageTile imageUrl="/images/paima-engine-start-sample.webp" text="" smallRadius />
            </div>
            <div className="mt-24 lg:h-full flex flex-col justify-between">
              <div>
                <h3 className="font-bold text-24">
                  <Trans>Paima Engine In Practice</Trans>
                </h3>
                <p className="mt-16">
                  <Trans>
                    Once you've grasped the basics, why not take a look at what Paima Engine has been used for in
                    practice? With bleeding-edge technology, new releases and use cases are constantly emerging,
                    providing both players and developers with an exciting environment and ecosystem to be a part of. In
                    the video below, we showcase some of the first games, examples, and templates to give you a taste of
                    where things stand today.
                  </Trans>
                </p>
              </div>
              <div className="mt-24">
                <VolcaneersLinkButton
                  outlineVariant
                  sizeVariant="small"
                  uppercaseText={false}
                  className="block md:inline-block lg:block"
                  href="https://www.youtube.com/watch?v=2-FTP-qbml8"
                  target="_blank"
                >
                  <Trans>Watch The Engine In Practice</Trans>
                </VolcaneersLinkButton>
              </div>
            </div>
          </div>
          <div className="md:basis-full lg:basis-[30%] flex-1 flex flex-col">
            <ImageTile
              imageUrl="/images/paima-engine-start-bizdev.webp"
              text={<Trans>Get In Touch With Our Team To Start Building Your Web3 Game</Trans>}
            />
            <div className="mt-24 lg:h-full flex flex-col justify-between">
              <div>
                <h3 className="font-bold text-24">
                  <Trans>Build Your Own Game</Trans>
                </h3>
                <p className="mt-16">
                  <Trans>
                    Paima Engine is already used in production for Paima Studio games, but it is in constant development
                    to become truly ready, from both an implementation and documentation perspective, for developers to
                    use themselves.
                  </Trans>
                  <br />
                  <br />
                  <Trans>
                    We are excited to offer early access to Paima Engine for high-quality teams with a solid track
                    record who wish to join us in building the future of Web3 Gaming.
                  </Trans>
                </p>
              </div>
              <div className="mt-24">
                <VolcaneersLinkButton
                  sizeVariant="small"
                  uppercaseText={false}
                  className="block"
                  href={getLocalizedLink('/contact', i18n.language)}
                  target="_blank"
                >
                  <Trans>Contact Us</Trans>
                </VolcaneersLinkButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartBuilding;
