import classNames from 'classnames';
import { Trans } from 'gatsby-plugin-react-i18next';
import React, { useState } from 'react';

import VolcaneersButton from '../volcaneers/VolcaneersButton';
import VolcaneersLinkButton from '../volcaneers/VolcaneersLinkButton';
import SectionTitle from './SectionTitle';

const games = [
  {
    logoUrl: '/images/paima-engine-logo-tower-defense.webp',
    name: <Trans>Wrath Of The Jungle: Tower Defense</Trans>,
    id: 'tower-defense',
    title: <Trans>Wrath Of The Jungle: Tower Defense</Trans>,
    description: (
      <Trans>
        Experience the strategic thrill of Web3 PvP Tower Defense in Paima Studio's upcoming game. Will you fight with
        the animals of the jungle to defend their homes, or join the undead and summon crypts to overpower them? A
        classic game genre, now expanded into the world of Web3 gaming thanks to Paima Engine.
      </Trans>
    ),
    ctaText: <Trans>Play Now</Trans>,
    ctaUrl: 'https://tower-defense.paimastudios.com/',
    bgImageClassName: "bg-[url('/images/paima-engine-games-tower-defense.webp')]",
    bgImageUrl: 'paima-engine-games-tower-defense.webp'
  },
  {
    logoUrl: '/images/paima-engine-logo-junglewars.webp',
    name: <Trans>Jungle Wars: NFT Rumble</Trans>,
    id: 'jungle-wars',
    title: <Trans>Jungle Wars: NFT Rumble</Trans>,
    description: (
      <Trans>
        The first casual PvP game was built to showcase the base tech underlying Paima Engine. Test your wits and
        craftiness while getting a feel for a completely new model of Web3 gaming.
      </Trans>
    ),
    ctaText: <Trans>Play Now</Trans>,
    ctaUrl: 'https://junglewars.paimastudios.com/',
    bgImageClassName: "bg-[url('/images/paima-engine-games-jungle-wars.webp')]",
    bgImageUrl: 'paima-engine-games-jungle-wars.webp'
  },
  {
    logoUrl: '/images/paima-engine-logo-tic-tac-toe.webp',
    name: <Trans>Tic Tac Toe</Trans>,
    id: 'tic-tac-toe',
    title: <Trans>Tic Tac Toe - Powered by Paima Engine</Trans>,
    description: (
      <Trans>
        Classic games like Tic Tac Toe serve as great starting points for developers to learn the basics of Paima
        Engine. It is one of the first games developers will be able to test for themselves when they gain access to
        Paima Engine.
      </Trans>
    ),
    ctaText: <Trans>Template Coming Soon</Trans>,
    bgImageClassName: "bg-[url('/images/paima-engine-games-tic-tac-toe.webp')]",
    bgImageUrl: 'paima-engine-games-tic-tac-toe.webp'
  },
  {
    logoUrl: '/images/paima-engine-logo-chess.webp',
    name: <Trans>Chess</Trans>,
    id: 'chess',
    title: <Trans>Chess - Powered by Paima Engine</Trans>,
    description: (
      <Trans>
        A timeless classic that every blockchain gaming engine should have an implementation of. Going beyond Tic Tac
        Toe, chess offers developers the opportunity to understand how games with more complex logic can be built using
        Paima Engine.
      </Trans>
    ),
    ctaText: <Trans>Template Coming Soon</Trans>,
    bgImageClassName: "bg-[url('/images/paima-engine-games-chess.webp')]",
    bgImageUrl: 'paima-engine-games-chess.webp'
  },
  {
    logoUrl: '/images/paima-engine-logo-secret-game.webp',
    name: <Trans>Stateful NFT Auto Battler</Trans>,
    id: 'secret-game',
    title: <Trans>Stateful NFT Auto Battler</Trans>,
    description: (
      <Trans>
        What happens when the animals of the jungle have thousands of years to master technology? In this upcoming Paima
        Studio game, Stateful NFTs will play a pivotal role in providing players with a taste of MMO-esque mechanics,
        combined with exciting autobattler-based gameplay.
      </Trans>
    ),
    ctaText: <Trans>Coming Soon</Trans>,
    bgImageClassName: "bg-[url('/images/paima-engine-games-secret-game.webp')]",
    bgImageUrl: 'paima-engine-games-secret-game.webp'
  }
];

const LaunchedGames = () => {
  const [currentGameId, setCurrentGameId] = useState(games[0].id);

  const currentGame = games.find((game) => game.id === currentGameId);

  if (!currentGame) {
    return null;
  }

  const GameSwitcher = () => {
    return (
      <ul className="space-y-4">
        {games.map((game) => {
          const isActive = game.id === currentGameId;

          return (
            <li
              key={game.id}
              className={classNames(
                isActive
                  ? 'bg-engine-primary text-white font-bold'
                  : 'bg-engine-gray/40 md:bg-engine-gray/20 text-engine-gray font-normal',
                'py-8 px-24 md:pt-16 md:pr-16 md:pb-16 md:pl-24',
                'text-18 text-center md:text-right',
                'rounded-[32px] md:rounded-tr-none md:rounded-br-none',
                'hover:cursor-pointer',
                'md:min-w-[280px]',
                'glass-bg'
              )}
              onClick={() => setCurrentGameId(game.id)}
            >
              {game.name}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div
      className={classNames(
        '',
        'relative',
        'py-56 md:py-80',
        currentGame.bgImageClassName,
        'bg-engine-green bg-cover bg-center bg-no-repeat 2xl:min-h-[1200px]'
      )}
    >
      <div className="container-slim">
        <SectionTitle number={4} title={<Trans>Launched Games</Trans>} darkText={false} />
        <div className="flex md:hidden flex-col justify-center mt-40">
          <GameSwitcher />
        </div>
        <div className="mt-40 md:mt-64">
          <div className="md:max-w-[50%] lg:max-w-[500px] rounded-[32px] glass-bg px-16 py-32 md:px-40 md:py-40">
            <img
              src={currentGame.logoUrl}
              alt={`Logo of ${currentGame.name}`}
              className="max-w-[140px] max-h-[100px] mb-[24px]"
            />
            <h3 className="font-bold text-32 text-white">{currentGame.title}</h3>
            <p className="mt-8 text-16 text-engine-gray">{currentGame.description}</p>
            <div className="mt-24">
              {!!currentGame.ctaUrl ? (
                <VolcaneersLinkButton
                  sizeVariant="small"
                  uppercaseText={false}
                  href={currentGame.ctaUrl}
                  className="block md:inline-block"
                >
                  {currentGame.ctaText}
                </VolcaneersLinkButton>
              ) : (
                <VolcaneersButton sizeVariant="small" uppercaseText={false} className="block md:inline-block">
                  {currentGame.ctaText}
                </VolcaneersButton>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:flex flex-col justify-center absolute top-0 bottom-0 right-0 h-full">
        <GameSwitcher />
      </div>
      <div className="hidden">
        {games.map((game, index) => (
          <img
            src={`/images/${game.bgImageUrl}`}
            alt=""
            className="hidden"
            loading={index === 0 ? 'eager' : 'lazy'}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export default LaunchedGames;
